import React from "react";
import img1 from '../../../assets/uniglobemar/workicon/concept_18366369 1.png'
import img2 from '../../../assets/uniglobemar/workicon/clock_18423910 1.png'
import img4 from '../../../assets/uniglobemar/workicon/programming_6932049 1.png'
import img3 from '../../../assets/uniglobemar/workicon/on-time_4381650 1.png'
import dot from '../../../assets/uniglobemar/workicon/dots.svg'

const HowWeWork = () => {
    return (
        <div style={{ backgroundColor: "rgb(246, 244, 249)" }}
        id="work"
        >
            <div className="container-fluid py-5 text-center">
                <h2 className="text-center mar_head" data-aos="fade-up">How We Work?</h2>
                <p className="text-muted mb-5" data-aos="fade-up">4 Steps Process</p>
                <div className="row justify-content-center">
                    {/* Step 1 */}
                    <div className="col-md-2 col-12 p-0  mb-4" data-aos="fade-up">
                        <div className="d-flex flex-column align-items-center">
                            <div className="position-relative">
                                <div className="rounded-circle bg-light p-4  shadow-sm">
                                    <img src={img1} alt="" className="zoom-icon"/>
                                </div>
                                <div
                                    className="position-absolute translate-middle badge rounded-pill bg-circl"
                                    style={{ width: "2.5rem", height: "2.5rem", top: "2px", right: "-20px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px" }}
                                >
                                    01
                                </div>
                            </div>
                            <h5 className="mt-3 fw-bold">Share Your Idea</h5>
                            <p className="para_mar_work text-center">
                            Share your idea, and we’ll bring your vision to life with  strategic <br className="d-lg-block d-none"/> solutions.  
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 p-0 mt-5 d-lg-block d-none" data-aos="fade-down">
                        <img src={dot} alt="" className="img-fluid"/>
                    </div>
                     {/* Step 4 */}
                     <div className="col-md-2 col-12 p-0 mb-4" data-aos="fade-up">
                        <div className="d-flex flex-column align-items-center">
                            <div className="position-relative">
                                <div className="rounded-circle bg-light p-4 shadow-sm">
                                    <img src={img2} alt="" className="zoom-icon"/>

                                </div>
                                <div
                                    className="position-absolute translate-middle badge rounded-pill bg-circl"
                                    style={{ width: "2.5rem", height: "2.5rem", top: "2px", right: "-20px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px" }}
                                >
                                    02
                                </div>
                            </div>
                            <h5 className="mt-3 fw-bold">Planning & Documentation</h5>
                            <p className="para_mar_work text-center">
                            To ensuring your projects stay organized, efficient, and <br className="d-lg-block d-none"/> goal-focused.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 mt-5 p-0 d-lg-block d-none" data-aos="fade-down">
                        <img src={dot} alt="" className="img-fluid" />
                    </div>
                    {/* Step 2 */}
                    <div className="col-md-2 col-12 p-0 mb-4" data-aos="fade-up">
                        <div className="d-flex flex-column align-items-center">
                            <div className="position-relative">
                                <div className="rounded-circle bg-light p-4 shadow-sm">
                                    <img src={img4} alt="" className="zoom-icon"/>

                                </div>
                                <div
                                    className="position-absolute translate-middle badge rounded-pill bg-circl"
                                    style={{ width: "2.5rem", height: "2.5rem", top: "2px", right: "-20px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px" }}
                                >
                                    03
                                </div>
                            </div>
                            <h5 className="mt-3 fw-bold">Let's Start Developments</h5>
                            <p className="para_mar_work text-center">
                            After the idea, we begin development, transforming concepts into innovative, high-impact solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 mt-5 p-0 d-lg-block d-none" data-aos="fade-down">
                        <img src={dot} alt="" className="img-fluid"/>
                    </div>
                    {/* Step 3 */}
                    <div className="col-md-2 col-12 mb-4 p-0" data-aos="fade-up">
                        <div className="d-flex flex-column align-items-center">
                            <div className="position-relative">
                                <div className="rounded-circle bg-light p-4 shadow-sm">
                                    <img src={img3} alt="" className="zoom-icon"/>

                                </div>
                                <div
                                    className="position-absolute translate-middle badge rounded-pill bg-circl"
                                    style={{ width: "2.5rem", height: "2.5rem", top: "2px", right: "-20px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px" }}
                                >
                                    04
                                </div>
                            </div>
                            <h5 className="mt-3 fw-bold">Ready To Launch</h5>
                            <p className="para_mar_work text-center">
                            After development, we optimize and prepare your project for a seamless launch experience.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowWeWork;
