import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import "./HeroCarousel.css";
// The Right Design For A Digital
const description = [
  "T", "h", "e", " ", " ", "R", "i", "g", "h", "t", " ", " ", "D", "e", "s", "i", "g", "n", " ", " ",
  "F", "o", "r", " ", " ", "A"
]
const description1 = ["D", "i", "g", "i", "t", "a", "l", " ", " ", "M", "a", "r", "k", "e", "t", "i", "n", "g"]

const HeroSection = () => {

  const [animate, setAnimate] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 500);
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    draggable: false,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="p-lg-0 p-0 w-100 hero-section">
        <div className="">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 col-12 mb-3 p-0">
              <Slider {...settings}>
                <div className="carousel-item bg_img">
                  <div className={`circle_round ${animate ? "animate" : ""}`}></div>
                  <h5 className="fw-normal text-center text-white" data-aos="fade-up" data-aos-delay="200" >
                    Welcome to our digital marketing agency
                  </h5>
                  <div className="d-flex">
                    {description.map((data, index) => (
                      <div
                        key={index}
                        className="text-white text-center main_text_mar mt-lg-0 mt-3"
                        data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}
                        data-aos-delay={index * 50}
                      >
                        {data === " " ? <span className="desktop_mob" style={{ display: "inline-block" }}></span> : data}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex">
                    {description1.map((data, index) => (
                      <div
                        key={index}
                        className="text-white text-center main_text_mar mt-lg-0 mt-3"
                        data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}
                        data-aos-delay={(index + 27) * 50}
                      >
                        {data === " " ? <span className="desktop_mob" style={{ display: "inline-block" }}></span> : data}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="carousel-item bg_img">
                  <div className="circle_round"></div>
                  <h5 className="fw-bold text-center text-white">Welcome to our digital marketing agency</h5>
                  <h1 className="text-white text-center main_text_mar mt-lg-0 mt-3">
                    The Right Design <br className="d-lg-block d-none" />
                    For A Digital <br className="d-lg-block d-none" />
                    Marketing
                  </h1>
                </div>
                <div className="carousel-item bg_img">
                  <div className="circle_round"></div>
                  <h5 className="fw-bold text-center text-white">Welcome to our digital marketing agency</h5>
                  <h1 className="text-white text-center main_text_mar mt-lg-0 mt-3">
                    The Right Design <br className="d-lg-block d-none" />
                    For A Digital <br className="d-lg-block d-none" />
                    Marketing
                  </h1>
                </div>
                <div className="carousel-item bg_img">
                  <div className="circle_round"></div>
                  <h5 className="fw-bold text-center text-white">Welcome to our digital marketing agency</h5>
                  <h1 className="text-white text-center main_text_mar mt-lg-0 mt-3">
                    The Right Design <br className="d-lg-block d-none" />
                    For A Digital <br className="d-lg-block d-none" />
                    Marketing
                  </h1>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PrevArrow = ({ onClick }) => (
  <button className="custom-prev" onClick={onClick}>
    <BsArrowLeft />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button className="custom-next" onClick={onClick}>
    <BsArrowRight />
  </button>
);

export default HeroSection;


