import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import tiktok from "../../assets/uniglobemar/icons/black_15047068.png"
import insta from "../../assets/uniglobemar/icons/instagram_1384015 (1).png"
import fb from "../../assets/uniglobemar/icons/facebook_2175193.png"
import utub from "../../assets/uniglobemar/icons/Layer 2.png"


const UniMarFooter = () => {
    return (
        <div className="footer_bg p-0 pb-0">
            <div className="border-bottom-fo">
                <div className="container py-0  border-white">
                    <div className="row align-items-center me-0 p-lg-0 p-3">
                        <div className="col-lg-3 height_web col-md-6 mt-lg-3  mb-lg-3 mb-3 col-12 d-flex flex-column justify-content-lg-start justify-content-center align-items-lg-start align-items-center">
                            <img
                                src={require("../../assets/uniglobemar/uni_footer.png")}
                                alt=""
                                style={{ height: "149px", width: "126px" }}
                            />
                            <p className="text-white mb-0">Follow us and Stay Updated!</p>
                            <div className="d-flex gap-3 mt-2 social_icons">
                                <img src={tiktok} />
                                <a href="https://www.instagram.com/uniglobemarketing?igsh=MWV4bGFkOHYwbHp2MA==" target="blank"><img src={insta} /></a>
                                <a href="https://www.facebook.com/share/1Gm5RamRbd/" target="blank"><img src={fb} /></a>
                                <img src={utub} />
                            </div>
                        </div>

                        <div className="col-lg-3 bor_left_fo col-md-6 col-12 d-flex flex-column justify-content-center text-center">
                            {/* <h3>Marketed By</h3> */}

                            <ul className="p-0" style={{ listStyle: 'none' }}>
                                <li className="mb-3 para_mar_fo">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <g clip-path="url(#clip0_272_120)">
                                            <path d="M21.7756 18.6219C21.0577 17.8527 19.8718 17.7245 19.0064 18.327L16.891 19.8078C16.1795 20.3014 15.1987 20.1347 14.7051 19.4232L9.89743 12.5899C9.44871 11.8078 9.53846 11.1091 10.1795 10.4937C10.9551 10.1668 11.7179 9.41678 12.4038 8.94242C13.2692 8.33986 13.5577 7.1796 13.0769 6.2437L10.8333 1.91037C9.84615 0.0065234 7.26923 -0.602451 5.52564 0.66037C3.66025 2.01293 1.64102 3.77575 0.820511 6.57063C-0.750002 11.9232 2.98718 17.2309 5.95513 21.3527C8.78205 25.2758 12.5128 30.0001 17.8397 30.0001C20.2756 30.0001 22.4808 28.9232 24.4615 27.5963C26.2436 26.404 26.5641 23.7822 25.0962 22.1988L21.7756 18.6219ZM6.28205 1.69883C7.42948 0.865498 9.05128 1.26934 9.6923 2.50011L11.9359 6.83345C12.1282 7.19883 12.0128 7.65396 11.6731 7.89114L10.0897 8.9937L5.5 2.28858C5.75641 2.08986 6.01923 1.89114 6.28205 1.69883ZM17.359 28.6988C12.6923 28.404 9.5 24.0642 7 20.5963C4.29487 16.8591 0.615383 11.827 2.05128 6.93601C2.51923 5.33986 3.4359 4.13473 4.51282 3.12832L9.07051 9.78217C8.18589 10.7501 8.08974 12.2437 8.84615 13.327L13.6538 20.1604C14.3397 21.1347 15.532 21.5514 16.6282 21.3014L21.9936 27.5963C20.6282 28.3142 19.109 28.8142 17.359 28.6988ZM24.75 24.8911C24.6731 25.5578 24.3077 26.154 23.7436 26.5322C23.5385 26.6668 23.3333 26.8014 23.1218 26.936L17.8205 20.7181L19.7436 19.3719C20.0833 19.1347 20.5513 19.186 20.8333 19.4873L24.1538 23.0706C24.609 23.5642 24.8269 24.2309 24.75 24.8911Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_272_120">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </li>
                                <li className="mb-2 fs-5 text-white">
                                    Call Us
                                </li>
                                <li className="mb-2">
                                    <a href="tel:03266619997" className="para_mar_fo">
                                        +92 326 6619997
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div className="col-lg-3 bor_left_fo col-md-6 col-12 d-flex flex-column justify-content-center text-center ">
                            {/* <h3>Marketed By</h3> */}

                            <ul className="p-0" style={{ listStyle: 'none' }}>
                                <li className="mb-3 para_mar_fo">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <g clip-path="url(#clip0_272_70)">
                                            <path d="M17.8406 25.8338C17.7316 25.7248 17.581 25.6621 17.4264 25.6621C17.2723 25.6621 17.1211 25.7248 17.0121 25.8338C16.9031 25.9428 16.8404 26.0939 16.8404 26.248C16.8404 26.4021 16.9031 26.5533 17.0121 26.6623C17.1211 26.7713 17.2723 26.834 17.4264 26.834C17.581 26.834 17.7316 26.7713 17.8406 26.6623C17.9496 26.5533 18.0123 26.4021 18.0123 26.248C18.0123 26.0939 17.9496 25.9428 17.8406 25.8338Z" fill="white" />
                                            <path d="M15 5.45068C12.2249 5.45068 9.96732 7.70836 9.96732 10.4834V10.5514C9.96732 13.3265 12.225 15.5841 15 15.5841H18.2696C18.5933 15.5841 18.8555 15.3218 18.8555 14.9982C18.8555 14.6746 18.5932 14.4122 18.2696 14.4122H14.9999C12.8711 14.4122 11.1391 12.6803 11.1391 10.5514V10.4834C11.1391 8.35453 12.8711 6.62256 14.9999 6.62256C17.1288 6.62256 18.8607 8.35453 18.8607 10.4834V11.5628C18.8607 11.945 18.5497 12.2561 18.1675 12.2561C17.7853 12.2561 17.4743 11.945 17.4743 11.5628V10.3435C17.4743 8.86869 16.2744 7.66881 14.7996 7.66881C13.2504 7.66881 11.9901 8.9291 11.9901 10.4783C11.9901 12.0274 13.2504 13.2877 14.7996 13.2877H15.6376C15.9612 13.2877 16.2235 13.0254 16.2235 12.7018C16.2235 12.3782 15.9612 12.1158 15.6376 12.1158H14.7997C13.8967 12.1158 13.1621 11.3812 13.1621 10.4783C13.1621 9.57533 13.8967 8.84068 14.7997 8.84068C15.6283 8.84068 16.3025 9.5148 16.3025 10.3435V11.5628C16.3025 12.5913 17.1391 13.4279 18.1676 13.4279C19.196 13.4279 20.0327 12.5913 20.0327 11.5628V10.4834C20.0327 7.7083 17.7751 5.45068 15 5.45068Z" fill="white" />
                                            <path d="M27.0158 21.7376C26.9068 21.6286 26.7557 21.5659 26.6016 21.5659C26.4475 21.5659 26.2963 21.6286 26.1873 21.7376C26.0783 21.8466 26.0156 21.9978 26.0156 22.1519C26.0156 22.306 26.0783 22.4571 26.1873 22.5661C26.2963 22.6751 26.4475 22.7378 26.6016 22.7378C26.7557 22.7378 26.9068 22.6751 27.0158 22.5661C27.1248 22.4571 27.1875 22.306 27.1875 22.1519C27.1875 21.9978 27.1248 21.8466 27.0158 21.7376Z" fill="white" />
                                            <path d="M29.7626 10.1912L16.796 0.598027C15.7217 -0.196738 14.2782 -0.196738 13.204 0.598027L0.237422 10.1912C0.0880664 10.3017 0 10.4765 0 10.6623V27.0685C0 28.6839 1.31426 29.9982 2.92969 29.9982H27.0703C28.6857 29.9982 30 28.6839 30 27.0685V10.6623C30 10.4765 29.9119 10.3017 29.7626 10.1912ZM13.9011 1.5401C14.5584 1.05377 15.4417 1.05377 16.099 1.5401L28.5189 10.7288L19.2464 20.0012L18.401 19.1558C16.5257 17.2805 13.4743 17.2805 11.599 19.1558L10.7535 20.0012L1.48113 10.7288L13.9011 1.5401ZM2.16697 28.5878C2.15285 28.6019 2.13967 28.6166 2.1273 28.6317C1.56059 28.3396 1.17188 27.7488 1.17188 27.0685V12.0769L9.9249 20.8299L2.16697 28.5878ZM3.58576 28.8263L5.57795 26.8341H15.1758C15.4994 26.8341 15.7617 26.5718 15.7617 26.2482C15.7617 25.9246 15.4994 25.6623 15.1758 25.6623H6.74982L8.39045 24.0216H12.725C13.0486 24.0216 13.3109 23.7593 13.3109 23.4357C13.3109 23.1121 13.0486 22.8498 12.725 22.8498H9.56232L11.1676 21.2445L11.1679 21.2442L11.1681 21.244L12.4276 19.9845C13.8461 18.566 16.1539 18.566 17.5724 19.9845L18.8319 21.244C18.8319 21.244 18.832 21.2442 18.8321 21.2442C18.8323 21.2443 18.8324 21.2445 18.8324 21.2445L26.4142 28.8263H3.58576ZM28.8281 27.0685C28.8281 27.7488 28.4394 28.3396 27.8727 28.6317C27.8603 28.6166 27.8471 28.6019 27.833 28.5877L20.0751 20.8299L26.0156 14.8894V19.7543C26.0156 20.0779 26.2779 20.3402 26.6016 20.3402C26.9252 20.3402 27.1875 20.0779 27.1875 19.7543V13.7175L28.8281 12.0769V27.0685Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_272_70">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </li>
                                <li className="mb-2 fs-5 text-white">
                                    Email
                                </li>
                                <li className="mb-2">
                                    <a href="mailto:inquiry@uniglobemarketing.com" className="para_mar_fo">
                                        inquiry@uniglobemarketing.com
                                    </a>
                                </li>


                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 bor_left_fo col-12 d-flex flex-column justify-content-center text-center">
                            {/* <h3>Marketed By</h3> */}

                            <ul className="p-0" style={{ listStyle: 'none' }}>
                                <li className="mb-3 para_mar_fo">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M20.0084 19.8214C23.2007 14.812 22.7994 15.437 22.8914 15.3064C24.0537 13.6671 24.668 11.7376 24.668 9.72656C24.668 4.39336 20.3402 0 15 0C9.67723 0 5.33203 4.38469 5.33203 9.72656C5.33203 11.7363 5.95922 13.7163 7.15957 15.3777L9.99152 19.8214C6.96369 20.2867 1.81641 21.6734 1.81641 24.7266C1.81641 25.8396 2.54285 27.4257 6.00363 28.6617C8.42016 29.5247 11.6151 30 15 30C21.3296 30 28.1836 28.2145 28.1836 24.7266C28.1836 21.6728 23.0423 20.2877 20.0084 19.8214ZM8.62787 14.4108C8.6182 14.3957 8.60812 14.381 8.59758 14.3664C7.59873 12.9923 7.08984 11.3637 7.08984 9.72656C7.08984 5.33098 10.6293 1.75781 15 1.75781C19.3617 1.75781 22.9102 5.33256 22.9102 9.72656C22.9102 11.3664 22.4109 12.9397 21.4661 14.2776C21.3814 14.3893 21.8231 13.703 15 24.4095L8.62787 14.4108ZM15 28.2422C8.08629 28.2422 3.57422 26.21 3.57422 24.7266C3.57422 23.7295 5.89266 22.0901 11.0302 21.4511L14.2588 26.5173C14.4202 26.7705 14.6996 26.9238 14.9999 26.9238C15.3002 26.9238 15.5798 26.7705 15.7411 26.5173L18.9697 21.4511C24.1073 22.0901 26.4258 23.7295 26.4258 24.7266C26.4258 26.1974 21.9543 28.2422 15 28.2422Z" fill="white" />
                                        <path d="M15 5.33203C12.5769 5.33203 10.6055 7.30342 10.6055 9.72656C10.6055 12.1497 12.5769 14.1211 15 14.1211C17.4231 14.1211 19.3945 12.1497 19.3945 9.72656C19.3945 7.30342 17.4231 5.33203 15 5.33203ZM15 12.3633C13.5461 12.3633 12.3633 11.1804 12.3633 9.72656C12.3633 8.27268 13.5461 7.08984 15 7.08984C16.4539 7.08984 17.6367 8.27268 17.6367 9.72656C17.6367 11.1804 16.4539 12.3633 15 12.3633Z" fill="white" />
                                    </svg>
                                </li>
                                <li className="mb-2 fs-5 text-white">
                                    Address
                                </li>
                                <li className="mb-2 para_mar_fo">
                                    Office #9 Union Complex,
                                    Ferozepur Road, Lahore,
                                    Pakistan.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-lg-flex d-block justify-content-between pt-4 pb-3">
                <p className="text-center text-white">
                    © Copyrights, 2024 Uniglobe Marketing
                </p>
                {/* <p className="text-white text-center">Designed & Developed by Vzone Solutions</p> */}
            </div>
        </div>
    );
};

export default UniMarFooter;
